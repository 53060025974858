import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Grid,
  Paper,
  Group,
  Collapse,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import { useMemberContext } from './MemberContext';

const CertificationForm: React.FC = () => {
  const { memberId } = useMemberContext();
  const { control, register, handleSubmit, reset, watch } = useForm({});
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'certifications',
  });

  const [collapsedIndices, setCollapsedIndices] = useState<boolean[]>([]);
  const [initialData, setInitialData] = useState<any>();

  const fetchCertifications = async () => {
    console.log('Fetching certifications for memberId:', memberId); // Debug statement
    try {
      const certificationsResponse = await fetch(
        `/api/certifications/${memberId}`,
      );
      console.log(
        'Certifications response status:',
        certificationsResponse.status,
      ); // Debug statement
      if (!certificationsResponse.ok) {
        throw new Error('Failed to fetch certifications');
      }
      const fetchedCertifications = await certificationsResponse.json();
      console.log('Fetched certifications:', fetchedCertifications); // Debug statement
  
      const sortedCertifications = fetchedCertifications.sort((a: any, b: any) => {
        const expirationDateComparison = new Date(a.expirationdate).getTime() - new Date(b.expirationdate).getTime();
        if (expirationDateComparison !== 0) return expirationDateComparison;
        const issueDateComparison = new Date(a.issuedate).getTime() - new Date(b.issuedate).getTime();
        if (issueDateComparison !== 0) return issueDateComparison;
        const certificateNumberComparison = a.certificatenumber.localeCompare(b.certificatenumber);
        if (certificateNumberComparison !== 0) return certificateNumberComparison;
        return a.id - b.id;
      });
  
      const certificationsextraResponse = await fetch(
        `/api/certificationsextra/${memberId}`,
      );
      console.log(
        'Certifications extra response status:',
        certificationsextraResponse.status,
      ); // Debug statement
      let fetchedExtra = { culturalcompetencytraining: '' };
  
      if (certificationsextraResponse.ok) {
        const textResponse = await certificationsextraResponse.text();
        if (textResponse) {
          fetchedExtra = JSON.parse(textResponse);
        }
      }
      console.log('Fetched certifications extra:', fetchedExtra); // Debug statement
  
      reset({
        certifications: sortedCertifications,
        certificationsextra: fetchedExtra,
      });
      setInitialData({
        certifications: sortedCertifications,
        certificationsextra: fetchedExtra,
      });
      setCollapsedIndices(sortedCertifications.map(() => true));
    } catch (error) {
      console.error('Error fetching certifications:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch certifications',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };
  
  useEffect(() => {
    if (memberId) {
      fetchCertifications();
    }
  }, [memberId]);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const onSubmit = async (data: any) => {
    console.log('Submit form values:', data); // Debug statement

    const formatData = (items: any[]) => {
      return items.map((item) => ({
        ...item,
        memberid: memberId,
        issuedate: item.issuedate
          ? new Date(item.issuedate).toISOString()
          : null,
        expirationdate: item.expirationdate
          ? new Date(item.expirationdate).toISOString()
          : null,
      }));
    };

    const formattedData = formatData(data.certifications);

    const createCertification = async (certification: any) => {
      console.log('Creating certification:', certification); // Debug statement
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/certifications`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(certification),
        },
      );

      console.log('Create certification response:', response); // Debug statement
      return response.json();
    };

    const updateCertification = async (certification: any) => {
      console.log('Updating certification:', certification); // Debug statement
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/certifications/${certification.id}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(certification),
        },
      );

      console.log('Update certification response:', response); // Debug statement
      return response.json();
    };

    const deleteCertification = async (id: number) => {
      console.log('Deleting certification ID:', id); // Debug statement
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/certifications/${id}`,
        {
          method: 'DELETE',
        },
      );

      console.log('Delete certification response:', response); // Debug statement
    };

    const createCertificationExtra = async (extraData: any) => {
      const extraDataWithMemberId = { ...extraData, memberid: memberId }; // Ensure memberid is included
      console.log('Creating certification extra:', extraDataWithMemberId); // Debug statement
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/certificationsextra`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(extraDataWithMemberId),
        },
      );

      console.log('Create certification extra response:', response); // Debug statement
      return response.json();
    };

    const updateCertificationExtra = async (extraData: any) => {
      const extraDataWithMemberId = { ...extraData, memberid: memberId }; // Ensure memberid is included
      console.log('Updating certification extra:', extraDataWithMemberId); // Debug statement
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/certificationsextra/${memberId}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(extraDataWithMemberId),
        },
      );

      console.log('Update certification extra response:', response); // Debug statement
      return response.json();
    };

    const deleteCertificationExtra = async () => {
      console.log('Deleting certification extra for memberId:', memberId); // Debug statement
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/certificationsextra/${memberId}`,
        {
          method: 'DELETE',
        },
      );

      console.log('Delete certification extra response:', response); // Debug statement
    };

    // Determine deletions, creations, and updates
    const initialIds = new Set<number>(
      initialData?.certifications.map((c: any) => c.id),
    );
    const currentIds = new Set<number>(formattedData.map((c: any) => c.id));
    const deletedIds = [...initialIds].filter(
      (id): id is number => !currentIds.has(id),
    );

    const createTasks = formattedData
      .filter((c: any) => !c.id || c.id === 0)
      .map((c: any) => createCertification(c));
    const updateTasks = formattedData
      .filter((c: any) => c.id && c.id > 0 && initialIds.has(c.id))
      .map((c: any) => updateCertification(c));

    const deleteTasks = deletedIds.map((id) => deleteCertification(id));

    let extraTask = null;
    if (data.certificationsextra.culturalcompetencytraining) {
      extraTask =
        initialData?.certificationsextra &&
        initialData.certificationsextra.culturalcompetencytraining
          ? updateCertificationExtra(data.certificationsextra)
          : createCertificationExtra(data.certificationsextra);
    } else if (
      initialData?.certificationsextra &&
      initialData.certificationsextra.culturalcompetencytraining
    ) {
      extraTask = deleteCertificationExtra();
    }

    try {
      const results = await Promise.all(
        [...createTasks, ...updateTasks, ...deleteTasks, extraTask].filter(
          Boolean,
        ),
      ); // Filter out null tasks
      console.log('Results:', results); // Debug statement
      fetchCertifications();

      notifications.show({
        title: 'Success',
        message: 'Certifications updated successfully',
        color: 'green',
        position: 'top-center',
      });
    } catch (error) {
      console.error('Error submitting certification form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit certification',
        color: 'red',
        position: 'top-center',
      });
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group>
              <h3>Certification {index + 1}</h3>
              <Button onClick={() => toggleCollapse(index)} size="xs">
                {collapsedIndices[index] ? 'Collapse' : 'Expand'}
              </Button>
              <Button color="red" onClick={() => remove(index)} size="xs">
                Remove
              </Button>
            </Group>
            <Collapse in={collapsedIndices[index]}>
              <Grid>
                <Grid.Col span={6}>
                  <Controller
                    name={`certifications.${index}.certificationname`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Certification Name"
                        data={[
                          {
                            value: 'Qualified Autism Service Provider (QASP)',
                            label: 'Qualified Autism Service Provider (QASP)',
                          },
                          {
                            value: 'Cardio Pulmonary Resuscitation (CPR)',
                            label: 'Cardio Pulmonary Resuscitation (CPR)',
                          },
                          {
                            value: 'Basic Life Support (BLS)',
                            label: 'Basic Life Support (BLS)',
                          },
                          {
                            value: 'Advanced Cardiac Life Support (ACLS)',
                            label: 'Advanced Cardiac Life Support (ACLS)',
                          },
                          {
                            value: 'Advanced Life Support in OB (ALSO)',
                            label: 'Advanced Life Support in OB (ALSO)',
                          },
                          {
                            value: 'Health Care Provider (CoreC)',
                            label: 'Health Care Provider (CoreC)',
                          },
                          {
                            value: 'Advanced Trauma Life Support (ATLS)',
                            label: 'Advanced Trauma Life Support (ATLS)',
                          },
                          {
                            value: 'Neonatal Advanced Life Support (NALS)',
                            label: 'Neonatal Advanced Life Support (NALS)',
                          },
                          {
                            value: 'Neonatal Resuscitation Program (NRP)',
                            label: 'Neonatal Resuscitation Program (NRP)',
                          },
                          {
                            value: 'Pediatric Advanced Life Support (PALS)',
                            label: 'Pediatric Advanced Life Support (PALS)',
                          },
                          {
                            value: 'Anesthesia Permit',
                            label: 'Anesthesia Permit',
                          },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Certificate Number"
                    {...register(`certifications.${index}.certificatenumber`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`certifications.${index}.issuedate`}
                    control={control}
                    render={({ field }) => (
                      <DateInput
                        {...field}
                        value={field.value ? new Date(field.value) : null}
                        onChange={(date) => field.onChange(date)}
                        label="Issue Date"
                        placeholder="Pick a date"
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`certifications.${index}.expirationdate`}
                    control={control}
                    render={({ field }) => (
                      <DateInput
                        {...field}
                        value={field.value ? new Date(field.value) : null}
                        onChange={(date) => field.onChange(date)}
                        label="Expiration Date"
                        placeholder="Pick a date"
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`certifications.${index}.state`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="State"
                        data={[
                          'AK',
                          'AL',
                          'AR',
                          'AS',
                          'AZ',
                          'CA',
                          'CO',
                          'CT',
                          'DC',
                          'DE',
                          'FL',
                          'GA',
                          'GU',
                          'HI',
                          'IA',
                          'ID',
                          'IL',
                          'IN',
                          'KS',
                          'KY',
                          'LA',
                          'MA',
                          'MD',
                          'ME',
                          'MI',
                          'MN',
                          'MO',
                          'MP',
                          'MS',
                          'MT',
                          'NC',
                          'ND',
                          'NE',
                          'NH',
                          'NJ',
                          'NM',
                          'NV',
                          'NY',
                          'OH',
                          'OK',
                          'OR',
                          'PA',
                          'PR',
                          'RI',
                          'SC',
                          'SD',
                          'TN',
                          'TX',
                          'UM',
                          'UT',
                          'VA',
                          'VI',
                          'VT',
                          'WA',
                          'WI',
                          'WV',
                          'WY',
                        ].map((state) => ({ value: state, label: state }))}
                        clearable
                        searchable
                      />
                    )}
                  />
                </Grid.Col>
              </Grid>
            </Collapse>
          </Paper>
        ))}
        <Group mt="md">
          <Button
            onClick={() =>
              append({
                certificationname: '',
                certificatenumber: '',
                issuedate: null,
                expirationdate: null,
                state: '',
              })
            }
          >
            Add Certification
          </Button>
        </Group>
        <Controller
          name="certificationsextra.culturalcompetencytraining"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              label="Have you completed cultural competency training?"
              data={[
                { value: 'Y', label: 'Yes' },
                { value: 'N', label: 'No' },
              ]}
              clearable
            />
          )}
        />
        <Group mt="md">
          <Button type="submit">Save</Button>
        </Group>
      </form>
    </Container>
  );
};

export default CertificationForm;
