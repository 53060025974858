import React from 'react';
import { Tabs, Grid } from '@mantine/core';
import PersonalInformationTab from './PersonalInformationTab';
import FamilyMemberManagementTab from './FamilyManagementTab';
import PaymentInformationTab from './PaymentInformationTab';
// import CopayDeductibleTrackingTab from './CopayDeductibleTrackingTab';
// import FinancialStatementsTab from './FinancialStatementsTab';
import HomeIcon from '../HomeIcon';
import LogoutIcon from '../LogoutIcon';
import DarkModeToggle from '../DarkModeToggle';

const InsuranceMemberPortal: React.FC = () => {
  return (
    <>
      <Grid justify="space-between">
        <Grid.Col span="auto">
          <HomeIcon />
          <LogoutIcon />
        </Grid.Col>
        <Grid.Col span="auto">
          <DarkModeToggle />
        </Grid.Col>
      </Grid>
      <Tabs defaultValue="personal-info">
        <Tabs.List>
          <Tabs.Tab value="personal-info">Personal Info</Tabs.Tab>
          <Tabs.Tab value="family-member-management">Family Member Management</Tabs.Tab>
          <Tabs.Tab value="payment-information">Payment Information</Tabs.Tab>
          <Tabs.Tab value="copay-deductible-tracking">Copay & Deductible Tracking</Tabs.Tab>
          <Tabs.Tab value="financial-statements">Financial Statements</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="personal-info">
          <PersonalInformationTab />
        </Tabs.Panel>
        <Tabs.Panel value="family-member-management">
          <FamilyMemberManagementTab />
        </Tabs.Panel>
        <Tabs.Panel value="payment-information">
          <PaymentInformationTab />
        </Tabs.Panel>
        {/* <Tabs.Panel value="copay-deductible-tracking">
          <CopayDeductibleTrackingTab />
        </Tabs.Panel>
        <Tabs.Panel value="financial-statements">
          <FinancialStatementsTab />
        </Tabs.Panel> */}
      </Tabs>
    </>
  );
};

export default InsuranceMemberPortal;
