import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MantineProvider, Container, Title } from '@mantine/core';
import AdminPortal from './components/AdminPortal';
import MemberPortal from './components/MemberPortal';
import { MemberProvider } from './components/MemberContext';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import AuthProvider from './components/AuthProvider';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/Login';
import PhysicianForm from './components/InsurancePortal/PhysicianForm';
import ClinicForm from './components/InsurancePortal/ClinicForm';
import InsuranceMemberPortal from './components/InsurancePortal/InsuranceMemberPortal';
const App: React.FC = () => {
  return (
    <MemberProvider>
      <Router>
        <AuthProvider>
          <MantineProvider>
            <Notifications />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route element={<ProtectedRoute />}>
                <Route
                  path="/"
                  element={
                    <Container size="md" style={{ marginTop: '2rem' }}>
                      <Title
                        order={1}
                        style={{ textAlign: 'center', marginBottom: '2rem' }}
                      >
                        Admin Portal
                      </Title>
                      <AdminPortal />
                    </Container>
                  }
                />
                <Route
                  path="/member/"
                  element={
                    <Container size="md" style={{ marginTop: '2rem' }}>
                      <Title
                        order={1}
                        style={{ textAlign: 'center', marginBottom: '2rem' }}
                      >
                        Member Portal
                      </Title>
                      <MemberPortal />
                    </Container>
                  }
                />
                <Route
                  path="/member/:id"
                  element={
                    <Container size="md" style={{ marginTop: '2rem' }}>
                      <Title
                        order={1}
                        style={{ textAlign: 'center', marginBottom: '2rem' }}
                      >
                        Member Portal
                      </Title>
                      <MemberPortal />
                    </Container>
                  }
                />
              </Route>
              <Route
                path="/member/error"
                element={
                  <Container size="md" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Error
                    </Title>
                  </Container>
                }
              />
              <Route
                  path="/physician/"
                  element={
                    <Container size="md" style={{ marginTop: '2rem' }}>
                      <Title
                        order={1}
                        style={{ textAlign: 'center', marginBottom: '2rem' }}
                      >
                        Physician Verification
                      </Title>
                      <PhysicianForm />
                    </Container>
                  }
                />
                <Route
                  path="/clinic/"
                  element={
                    <Container size="md" style={{ marginTop: '2rem' }}>
                      <Title
                        order={1}
                        style={{ textAlign: 'center', marginBottom: '2rem' }}
                      >
                        Clinic Verification
                      </Title>
                      <ClinicForm />
                    </Container>
                  }
                />
                <Route
                  path="/memberportal/"
                  element={
                    <Container size="md" style={{ marginTop: '2rem' }}>
                      <Title
                        order={1}
                        style={{ textAlign: 'center', marginBottom: '2rem' }}
                      >
                        Member Portal
                      </Title>
                      <InsuranceMemberPortal />
                    </Container>
                  }
                />
            </Routes>
          </MantineProvider>
        </AuthProvider>
      </Router>
    </MemberProvider>
  );
};

export default App;
