import React, { useState, useEffect } from 'react';
import { Container, TextInput, Button, Card, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useMemberContext } from './MemberContext';
import { Member } from '../types/members.types';
import DarkModeToggle from './DarkModeToggle';

const AdminPortal: React.FC = () => {
  const [members, setMembers] = useState<Member[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { setIsNewMember, setMemberId } = useMemberContext();
  const navigate = useNavigate();

  const fetchMembers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/members`,
      );
      const data = await response.json();
      setMembers(data);
    } catch (error) {
      console.error('Error fetching members', error);
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredMembers = members
    .filter((member) =>
      `${member.firstname} ${member.lastname} ${member.npi}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (a.lastname > b.lastname) return 1;
      if (a.lastname < b.lastname) return -1;
      if (a.firstname > b.firstname) return 1;
      if (a.firstname < b.firstname) return -1;
      return 0;
    });

  const handleMemberClick = async (memberId: number) => {
    setIsNewMember(false);
    setMemberId(memberId);
    navigate(`/member/${memberId}`);
  };

  const handleAddNewMember = () => {
    setIsNewMember(true);
    setMemberId(0);
    navigate('/member');
  };

  return (
    <Container>
      <DarkModeToggle />
      <Group mb="md">
        <TextInput
          placeholder="Search members"
          value={searchTerm}
          onChange={handleSearch}
        />
        <Button onClick={handleAddNewMember}>Add New Member</Button>
      </Group>

      <Group>
        {filteredMembers.map((member) => (
          <Card
            key={member.id}
            onClick={() => handleMemberClick(member.id)}
            shadow="sm"
            p="lg"
            style={{ cursor: 'pointer' }}
          >
            <h3>{`${member.firstname ?? ''} ${member.lastname ?? ''}`}</h3>
            {member.npi && <p>NPI: {member.npi}</p>}
          </Card>
        ))}
      </Group>
    </Container>
  );
};

export default AdminPortal;
