import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Paper,
  Group,
  Grid,
  Textarea,
  Collapse,
  Select,
  MultiSelect,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import { useMemberContext } from './MemberContext';

type TrainingFields = {
  id?: number;
  trainingtype: string;
  institutionhospitalname: string;
  specialty: string;
  subspecialty: string[];
  startdate: string | null;
  enddate: string | null;
  description: string;
};

type FormValues = {
  degree: string;
  medicalschool: string;
  educationstartdate: string | null;
  educationenddate: string | null;
  trainings: TrainingFields[];
};

const specialties = {
  'Allergy and Immunology': [],
  Anesthesiology: [
    'Adult Cardiac Anesthesiology',
    'Critical Care Medicine',
    'Health Care Administration, Leadership, and Management',
    'Hospice and Palliative Medicine',
    'Neurocritical Care',
    'Pain Medicine',
    'Pediatric Anesthesiology',
    'Sleep Medicine',
  ],
  'Colon and Rectal Surgery': [],
  Dermatology: [
    'Dermatopathology',
    'Micrographic Dermatologic Surgery',
    'Pediatric Dermatology',
  ],
  'Emergency Medicine': [
    'Anesthesiology Critical Care Medicine',
    'Emergency Medical Services',
    'Health Care Administration, Leadership, and Management',
    'Hospice and Palliative Medicine',
    'Internal Medicine-Critical Care Medicine',
    'Medical Toxicology',
    'Neurocritical Care',
    'Pain Medicine',
    'Pediatric Emergency Medicine',
    'Sports Medicine',
    'Undersea and Hyperbaric Medicine',
  ],
  'Family Medicine': [
    'Adolescent Medicine',
    'Geriatric Medicine',
    'Health Care Administration, Leadership, and Management',
    'Hospice and Palliative Medicine',
    'Pain Medicine',
    'Sleep Medicine',
    'Sports Medicine',
  ],
  'Internal Medicine': [
    'Adult Congenital Heart Disease',
    'Advanced Heart Failure and Transplant Cardiology',
    'Cardiovascular Disease',
    'Clinical Cardiac Electrophysiology',
    'Critical Care Medicine',
    'Endocrinology, Diabetes and Metabolism',
    'Gastroenterology',
    'Geriatric Medicine',
    'Hematology',
    'Hospice and Palliative Medicine',
    'Infectious Disease',
    'Interventional Cardiology',
    'Medical Oncology',
    'Nephrology',
    'Neurocritical Care',
    'Pulmonary Disease',
    'Rheumatology',
    'Sleep Medicine',
    'Sports Medicine',
    'Transplant Hepatology',
  ],
  'Medical Genetics and Genomics': [
    'Clinical Biochemical Genetics',
    'Clinical Genetics and Genomics (MD)',
    'Laboratory Genetics and Genomics',
    'Medical Biochemical Genetics',
    'Molecular Genetic Pathology',
  ],
  'Neurological Surgery': ['Neurocritical Care'],
  'Nuclear Medicine': [],
  'Obstetrics and Gynecology': [
    'Complex Family Planning',
    'Critical Care Medicine',
    'Gynecologic Oncology',
    'Maternal-Fetal Medicine',
    'Reproductive Endocrinology and Infertility',
    'Urogynecology and Reconstructive Pelvic Surgery',
  ],
  Ophthalmology: [],
  'Orthopaedic Surgery': ['Orthopaedic Sports Medicine', 'Surgery of the Hand'],
  'Otolaryngology - Head and Neck Surgery': [
    'Complex Pediatric Otolaryngology',
    'Neurotology',
    'Plastic Surgery Within the Head and Neck',
    'Sleep Medicine',
  ],
  Pathology: [
    'Blood Banking/Transfusion Medicine',
    'Clinical Informatics',
    'Cytopathology',
    'Dermatopathology',
    'Hematopathology',
    'Neuropathology',
    'Pathology - Chemical',
    'Pathology - Forensic',
    'Pathology - Medical Microbiology',
    'Pathology - Molecular Genetic',
    'Pathology - Pediatric',
  ],
  Pediatrics: [
    'Adolescent Medicine',
    'Child Abuse Pediatrics',
    'Developmental-Behavioral Pediatrics',
    'Hospice and Palliative Medicine',
    'Medical Toxicology',
    'Neonatal-Perinatal Medicine',
    'Pediatric Cardiology',
    'Pediatric Critical Care Medicine',
    'Pediatric Emergency Medicine',
    'Pediatric Endocrinology',
    'Pediatric Gastroenterology',
    'Pediatric Hematology-Oncology',
    'Pediatric Hospital Medicine',
    'Pediatric Infectious Diseases',
    'Pediatric Nephrology',
    'Pediatric Pulmonology',
    'Pediatric Rheumatology',
    'Pediatric Transplant Hepatology',
    'Sleep Medicine',
    'Sports Medicine',
  ],
  'Physical Medicine and Rehabilitation': [
    'Brain Injury Medicine',
    'Neuromuscular Medicine',
    'Pain Medicine',
    'Pediatric Rehabilitation Medicine',
    'Spinal Cord Injury Medicine',
    'Sports Medicine',
  ],
  'Plastic Surgery': [
    'Plastic Surgery Within the Head and Neck',
    'Surgery of the Hand',
  ],
  'Preventive Medicine': [
    'Aerospace Medicine',
    'Occupational and Environmental Medicine',
    'Public Health and General Preventive Medicine',
    'Addiction Medicine',
    'Clinical Informatics',
    'Health Care Administration, Leadership, and Management',
    'Medical Toxicology',
    'Undersea and Hyperbaric Medicine',
  ],
  'Psychiatry and Neurology': [
    'Addiction Psychiatry',
    'Brain Injury Medicine',
    'Child and Adolescent Psychiatry',
    'Clinical Neurophysiology',
    'Consultation-Liaison Psychiatry',
    'Epilepsy',
    'Forensic Psychiatry',
    'Geriatric Psychiatry',
    'Neurocritical Care',
    'Neurodevelopmental Disabilities',
    'Neuromuscular Medicine',
    'Pain Medicine',
    'Sleep Medicine',
    'Vascular Neurology',
  ],
  Radiology: [
    'Diagnostic Radiology',
    'Interventional Radiology and Diagnostic Radiology',
    'Medical Physics (Diagnostic, Nuclear, Therapeutic)',
    'Radiation Oncology',
    'Neuroradiology',
    'Nuclear Radiology',
    'Pain Medicine',
    'Pediatric Radiology',
  ],
  Surgery: [
    'General Surgery',
    'Vascular Surgery',
    'Complex General Surgical Oncology',
    'Pediatric Surgery',
    'Surgery of the Hand',
    'Surgical Critical Care',
  ],
  'Thoracic Surgery': ['Congenital Cardiac Surgery'],
  Urology: [
    'Pediatric Urology',
    'Urogynecology and Reconstructive Pelvic Surgery',
  ],
};

const EducationTrainingForm: React.FC = () => {
  const { memberId } = useMemberContext();
  const { control, register, handleSubmit, watch, setValue, reset } =
    useForm<FormValues>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'trainings',
  });

  const [collapsedIndices, setCollapsedIndices] = useState<boolean[]>([]);
  const [initialData, setInitialData] = useState<FormValues | null>(null);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const fetchEducationAndTraining = async () => {
    console.log('Fetching education and training for memberId:', memberId);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/educationtraining/${memberId}`,
      );
      console.log('Education and training response status:', response.status);
      if (!response.ok) {
        throw new Error('Failed to fetch education and training');
      }
      const data = await response.json();
      console.log('Fetched education and training:', data);
  
      const sortedTrainings = data.training.sort((a: TrainingFields, b: TrainingFields) => {
        if (a.enddate && b.enddate) {
          const endDateComparison = new Date(a.enddate).getTime() - new Date(b.enddate).getTime();
          if (endDateComparison !== 0) return endDateComparison;
        }
        if (a.startdate && b.startdate) {
          const startDateComparison = new Date(a.startdate).getTime() - new Date(b.startdate).getTime();
          if (startDateComparison !== 0) return startDateComparison;
        }
        return a.id - b.id;
      });
  
      const educationData = {
        degree: data.education.degree || '',
        medicalschool: data.education.medicalschool || '',
        educationstartdate: data.education.educationstartdate || null,
        educationenddate: data.education.educationenddate || null,
        trainings: sortedTrainings.length
          ? sortedTrainings
          : [
              {
                trainingtype: '',
                institutionhospitalname: '',
                specialty: '',
                subspecialty: [],
                startdate: null,
                enddate: null,
                description: '',
              },
            ],
      };
  
      reset(educationData);
      setInitialData(educationData);
      setCollapsedIndices(
        sortedTrainings.length ? sortedTrainings.map(() => true) : [true],
      );
    } catch (error) {
      console.error('Error fetching education and training:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch education and training',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };
  
  useEffect(() => {
    if (memberId) {
      fetchEducationAndTraining();
    }
  }, [memberId]);

  const onSubmit = async (data: FormValues) => {
    console.log('Submit form values:', data);

    const formatData = (items: TrainingFields[]) => {
      return items.map((item) => ({
        ...item,
        memberid: memberId,
        startdate: item.startdate
          ? new Date(item.startdate).toISOString()
          : null,
        enddate: item.enddate ? new Date(item.enddate).toISOString() : null,
      }));
    };

    const formattedTrainings = formatData(data.trainings);
    const educationData = {
      ...data,
      memberid: memberId,
      educationstartdate: data.educationstartdate
        ? new Date(data.educationstartdate).toISOString()
        : null,
      educationenddate: data.educationenddate
        ? new Date(data.educationenddate).toISOString()
        : null,
    };

    const createEducation = async (education: any) => {
      console.log('Creating education:', education);
      const response = await fetch('/api/educationtraining/education', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(education),
      });

      console.log('Create education response:', response);
      return response.json();
    };

    const updateEducation = async (education: any) => {
      console.log('Updating education:', education);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/educationtraining/education/${memberId}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(education),
        },
      );

      console.log('Update education response:', response);
      return response.json();
    };

    const createTraining = async (training: any) => {
      console.log('Creating training:', training);
      const response = await fetch('/api/educationtraining/training', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(training),
      });

      console.log('Create training response:', response);
      return response.json();
    };

    const updateTraining = async (training: any) => {
      console.log('Updating training:', training);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/educationtraining/training/${training.id}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(training),
        },
      );

      console.log('Update training response:', response);
      return response.json();
    };

    const deleteTraining = async (id: number) => {
      console.log('Deleting training ID:', id);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/educationtraining/training/${id}`,
        {
          method: 'DELETE',
        },
      );

      console.log('Delete training response:', response);
    };

    // Determine deletions, creations, and updates
    const initialIds = new Set<number>(
      initialData?.trainings.map((t: any) => t.id) || [],
    );
    const currentIds = new Set<number>(
      formattedTrainings.map((t: any) => t.id),
    );
    const deletedIds = [...initialIds].filter(
      (id): id is number => !currentIds.has(id),
    );

    const createTasks = formattedTrainings
      .filter((t: any) => !t.id || t.id === 0)
      .map((t: any) => createTraining(t));
    const updateTasks = formattedTrainings
      .filter((t: any) => t.id && t.id > 0 && initialIds.has(t.id))
      .map((t: any) => updateTraining(t));

    const deleteTasks = deletedIds.map((id) => deleteTraining(id));

    const educationTask =
      initialData?.degree || initialData?.medicalschool
        ? updateEducation(educationData)
        : createEducation(educationData);

    try {
      const results = await Promise.all([
        ...createTasks,
        ...updateTasks,
        ...deleteTasks,
        educationTask,
      ]);
      console.log('Results:', results);
      fetchEducationAndTraining();

      notifications.show({
        title: 'Success',
        message: 'Education and training updated successfully',
        color: 'green',
        position: 'bottom-right',
      });
    } catch (error) {
      console.error('Error submitting education and training form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit education and training',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  const trainingSpecialties = fields.map(
    (_, index) =>
      watch(`trainings.${index}.specialty`) as keyof typeof specialties,
  );

  useEffect(() => {
    trainingSpecialties.forEach((specialty, index) => {
      if (
        initialData?.trainings[index] &&
        initialData.trainings[index].specialty !== specialty
      ) {
        setValue(`trainings.${index}.subspecialty`, []);
      }
    });
  }, [trainingSpecialties, setValue, initialData]);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper withBorder shadow="xs" p="md" mt="md">
          <Group>
            <h3>Education</h3>
          </Group>
          <Grid>
            <Grid.Col span={6}>
              <Controller
                name="degree"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Degree"
                    data={[
                      { value: 'MD', label: 'MD' },
                      { value: 'DO', label: 'DO' },
                    ]}
                    clearable
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Name of Medical School"
                {...register('medicalschool')}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="educationstartdate"
                control={control}
                render={({ field }) => (
                  <DateInput
                    {...field}
                    label="Start Date"
                    placeholder="Pick a date"
                    value={field.value ? new Date(field.value) : null}
                    onChange={(date) =>
                      field.onChange(date ? date.toISOString() : null)
                    }
                    clearable
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="educationenddate"
                control={control}
                render={({ field }) => (
                  <DateInput
                    {...field}
                    label="End Date"
                    placeholder="Pick a date"
                    value={field.value ? new Date(field.value) : null}
                    onChange={(date) =>
                      field.onChange(date ? date.toISOString() : null)
                    }
                    clearable
                  />
                )}
              />
            </Grid.Col>
          </Grid>
        </Paper>

        {fields.map((item, index) => (
          <Paper key={item.id} withBorder shadow="xs" p="md" mt="md">
            <Group>
              <h3>Training {index + 1}</h3>
              <Button onClick={() => toggleCollapse(index)} size="xs">
                {collapsedIndices[index] ? 'Collapse' : 'Expand'}
              </Button>
              <Button color="red" onClick={() => remove(index)} size="xs">
                Remove
              </Button>
            </Group>
            <Collapse in={collapsedIndices[index]}>
              <Grid>
                <Grid.Col span={6}>
                  <Controller
                    name={`trainings.${index}.trainingtype`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Training Type"
                        data={[
                          { value: 'Internship', label: 'Internship' },
                          { value: 'Residency', label: 'Residency' },
                          { value: 'Fellowship', label: 'Fellowship' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Institution/Hospital Name"
                    {...register(`trainings.${index}.institutionhospitalname`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`trainings.${index}.specialty`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Specialty"
                        data={Object.keys(specialties).map((specialty) => ({
                          value: specialty,
                          label: specialty,
                        }))}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`trainings.${index}.subspecialty`}
                    control={control}
                    render={({ field }) => (
                      <MultiSelect
                        {...field}
                        label="Subspecialty"
                        data={
                          trainingSpecialties[index]
                            ? specialties[trainingSpecialties[index]]
                            : []
                        }
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`trainings.${index}.startdate`}
                    control={control}
                    render={({ field }) => (
                      <DateInput
                        {...field}
                        label="Start Date"
                        placeholder="Pick a date"
                        value={field.value ? new Date(field.value) : null}
                        onChange={(date) =>
                          field.onChange(date ? date.toISOString() : null)
                        }
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`trainings.${index}.enddate`}
                    control={control}
                    render={({ field }) => (
                      <DateInput
                        {...field}
                        label="End Date"
                        placeholder="Pick a date"
                        value={field.value ? new Date(field.value) : null}
                        onChange={(date) =>
                          field.onChange(date ? date.toISOString() : null)
                        }
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Textarea
                    label="Description"
                    {...register(`trainings.${index}.description`)}
                  />
                </Grid.Col>
              </Grid>
            </Collapse>
          </Paper>
        ))}
        <Group mt="md" mb="lg">
          <Button
            onClick={() =>
              append({
                trainingtype: '',
                institutionhospitalname: '',
                specialty: '',
                subspecialty: [],
                startdate: null,
                enddate: null,
                description: '',
              })
            }
          >
            Add Professional Training
          </Button>
        </Group>

        <Group mt="md">
          <Button type="submit">Save</Button>
        </Group>
      </form>
    </Container>
  );
};

export default EducationTrainingForm;
