import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Input,
  Grid,
  Title,
  Paper
} from '@mantine/core';
import { STATES_ARRAY } from '../../types/members.types';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';

interface ClinicFormProps {
  initialData?: any;
  onFormSubmit?: (data: any) => void;
}

const ClinicForm: React.FC<ClinicFormProps> = ({ initialData, onFormSubmit }) => {
  const { control, register, handleSubmit, reset } = useForm({
    defaultValues: initialData,
  });

  const onSubmit = async (data: any) => {
    try {
      if (onFormSubmit) {
        onFormSubmit(data);
      }
      notifications.show({
        title: 'Success',
        message: 'Form submitted successfully',
        color: 'green',
        position: 'bottom-right',
      });
    } catch (error) {
      console.error('Error submitting form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit form',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  useEffect(() => {
    if (initialData) {
      reset(initialData);
    }
  }, [initialData, reset]);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              label="Business Name"
              required
              {...register('businessname', { required: true })}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
                label="Practice Website"
                required
                {...register('tin', { required: true })}
            />
          </Grid.Col>
        </Grid>
        <Grid>
            <Grid.Col span={6}>
                <TextInput
                    label="Email"
                    required
                    {...register('email', { required: true })}
                />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                  label="Tax Identification Number"
                  required
                  {...register('tin', { required: true })}
              />
            </Grid.Col>
        </Grid>
        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Address Information</Title>
          <TextInput label="Street 1" required {...register('street1')} />
          <TextInput label="Street 2" required {...register('street2')} />
          <Grid>
            <Grid.Col span={6}>
              <TextInput label="City" required {...register('city')} />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="State"
                    data={STATES_ARRAY}
                    clearable
                    searchable
                    required
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput label="ZIP" required {...register('zip')} />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Country"
                    data={[{ value: 'US', label: 'United States' }]}
                    clearable
                    required
                  />
                )}
              />
            </Grid.Col>
          </Grid>
          <TextInput label="County" required {...register('county')} />
        </Paper>
        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Administrator Information</Title>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Administrator First Name"
                required
                {...register('adminfirstname', { required: true })}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Administrator Last Name"
                required
                {...register('adminlastname', { required: true })}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Administrator Email"
                required
                {...register('adminemail', { required: true })}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="adminphone"
                control={control}
                render={({ field }) => (
                  <Input.Wrapper label="Administrator Phone">
                    <Input
                      component={IMaskInput}
                      {...field}
                      mask="(000) 000-0000"
                      placeholder="(XXX) XXX-XXXX"
                    />
                  </Input.Wrapper>
                )}
              />
            </Grid.Col>
          </Grid>
        </Paper>
        <Button type="submit" mt="md">
          Submit
        </Button>
      </form>
    </Container>
  );
};

export default ClinicForm;
