import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Input,
  Grid
} from '@mantine/core';
import { STATES_ARRAY } from '../../types/members.types';
import { DateInput } from '@mantine/dates';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';

interface PhysicianFormProps {
  initialData?: any;
  onFormSubmit?: (data: any) => void;
}

const PhysicianForm: React.FC<PhysicianFormProps> = ({ initialData, onFormSubmit }) => {
  const { control, register, handleSubmit, reset } = useForm({
    defaultValues: initialData,
  });

  const onSubmit = async (data: any) => {
    try {
      if (onFormSubmit) {
        onFormSubmit(data);
      }
      notifications.show({
        title: 'Success',
        message: 'Form submitted successfully',
        color: 'green',
        position: 'bottom-right',
      });
    } catch (error) {
      console.error('Error submitting form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit form',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  useEffect(() => {
    if (initialData) {
      reset(initialData);
    }
  }, [initialData, reset]);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              label="First Name"
              required
              {...register('firstname', { required: true })}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Last Name"
              required
              {...register('lastname', { required: true })}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              label="Email"
              required
              {...register('email', { required: true })}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="birthdate"
              control={control}
              render={({ field }) => (
                <DateInput
                  label="Date of Birth"
                  placeholder="Date of Birth"
                  value={field.value ? new Date(field.value) : undefined}
                  onChange={(date) =>
                    field.onChange(date?.toISOString().slice(0, 10))
                  }
                  clearable
                  required
                />
              )}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              label="Medical License Number"
              required
              {...register('licensenumber', { required: true })}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="licensestate"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Medical License State"
                  data={STATES_ARRAY}
                  clearable
                  searchable
                  required
                />
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
            name="licenseexpiration"
            control={control}
            render={({ field }) => (
              <DateInput
                label="License Expiration Date"
                placeholder="License Expiration Date"
                value={field.value ? new Date(field.value) : undefined}
                onChange={(date) =>
                  field.onChange(date?.toISOString().slice(0, 10))
                }
                clearable
                required
              />
            )}
          />
        </Grid.Col>
        </Grid>
        <Button type="submit" mt="md">
          Submit
        </Button>
      </form>
    </Container>
  );
};

export default PhysicianForm;
