import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Grid,
  Paper,
  Title,
  Input,
  Select,
  ActionIcon,
  Table,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';
import { Pencil, Lock, Plus } from 'tabler-icons-react';
import { STATES_ARRAY } from '../../types/members.types';
import './css/FamilyManagementTab.css';

interface FamilyMember {
  id: number;
  firstname: string;
  lastname: string;
  birthdate: string;
  ssn: string;
  [key: string]: any;
}

const initialFamilyMembers: FamilyMember[] = [
  { id: 1, firstname: 'Jane', lastname: 'Doe', birthdate: '1995-06-15', ssn: '123-45-6789' },
  { id: 2, firstname: 'John', lastname: 'Doe', birthdate: '1998-12-22', ssn: '987-65-4321' }
];

const FamilyManagementTab: React.FC = () => {
  const [familyMembers, setFamilyMembers] = useState<FamilyMember[]>(initialFamilyMembers);
  const [selectedMember, setSelectedMember] = useState<FamilyMember | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  const { control, register, handleSubmit, reset } = useForm({
    defaultValues: selectedMember || {}
  });

  const onSubmit = async (data: any) => {
    try {
      notifications.show({
        title: 'Success',
        message: 'Family member updated successfully',
        color: 'green',
        position: 'bottom-right',
      });
      setIsEditing(false);
      if (selectedMember) {
        setFamilyMembers((prevMembers) =>
          prevMembers.map((member) => (member.id === selectedMember.id ? { ...member, ...data } : member))
        );
      }
    } catch (error) {
      console.error('Error submitting form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit form',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  const handleAddFamilyMember = () => {
    const newMember = { id: familyMembers.length + 1, firstname: '', lastname: '', birthdate: '', ssn: '' };
    setFamilyMembers([...familyMembers, newMember]);
    setSelectedMember(newMember);
    setIsEditing(true);
    reset(newMember);
  };

  const handleSelectMember = (member: FamilyMember) => {
    setSelectedMember(member);
    setIsEditing(false);
    reset(member);
  };

  return (
    <Container>
      <Grid>
        <Grid.Col span={4}>
          <Paper withBorder shadow="xs" p="md">
            <Title order={4}>Family Members</Title>
            <Table highlightOnHover>
              <tbody>
                {familyMembers.map((member) => (
                  <tr
                    key={member.id}
                    onClick={() => handleSelectMember(member)}
                    className={`family-member-row ${member === selectedMember ? 'selected-member' : ''}`}
                  >
                    <td>
                      {member.firstname} {member.lastname}
                    </td>
                  </tr>
                ))}
                <tr onClick={handleAddFamilyMember} className="family-member-row add-member-row">
                  <td>
                    <Plus size={10} color="#238be6" /> Add a Family Member
                  </td>
                </tr>
              </tbody>
            </Table>
          </Paper>
        </Grid.Col>
        <Grid.Col span={8}>
          {selectedMember && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Paper withBorder shadow="xs" p="md">
                <Grid justify="space-between" align="center">
                  <Grid.Col span={11}>
                    <Title order={3}>{selectedMember.firstname} {selectedMember.lastname}</Title>
                  </Grid.Col>
                  <Grid.Col span={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ActionIcon
                      onClick={() => setIsEditing((prev) => !prev)}
                      variant="outline"
                      color="blue"
                    >
                      {isEditing ? <Lock size={16} /> : <Pencil size={16} />}
                    </ActionIcon>
                  </Grid.Col>
                </Grid>
                <Paper withBorder shadow="xs" p="md" mt="md">
                  <Title order={3}>Basic Information</Title>
                  <Grid>
                    <Grid.Col span={6}>
                      <TextInput
                        label="First Name"
                        {...register('firstname', { required: true })}
                        disabled={!isEditing}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Last Name"
                        {...register('lastname', { required: true })}
                        disabled={!isEditing}
                      />
                    </Grid.Col>
                  </Grid>
                  <Controller
                    name="birthdate"
                    control={control}
                    render={({ field }) => (
                      <DateInput
                        label="Date of Birth"
                        value={field.value ? new Date(field.value) : undefined}
                        onChange={(date) => field.onChange(date?.toISOString().slice(0, 10))}
                        disabled={!isEditing}
                      />
                    )}
                  />
                </Paper>
                <Paper withBorder shadow="xs" p="md" mt="md">
                  <Title order={3}>Complex Information</Title>
                  <Controller
                    name="ssn"
                    control={control}
                    render={({ field }) => (
                      <Input.Wrapper label="SSN">
                        <Input
                          component={IMaskInput}
                          {...field}
                          mask="000-00-0000"
                          placeholder="XXX-XX-XXXX"
                          disabled={!isEditing}
                        />
                      </Input.Wrapper>
                    )}
                  />
                </Paper>
                {isEditing && (
                  <Button type="submit" mt="md">
                    Submit
                  </Button>
                )}
              </Paper>
            </form>
          )}
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default FamilyManagementTab;
