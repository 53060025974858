import { useContext, createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext({
  screen: 'auth',
  loginAuth: async ({}: any) => {},
  logoutAuth: async () => {},
});

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [screen, setScreen] = useState('auth');

  const loginAuth = async (data) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/auth`, {
        method: 'GET',
        headers: {
          Authorization: `Basic ${btoa(`${data.username}:${data.password}`)}`,
        },
      });
      const resData = await res.json();
      console.log('resData', resData);
      if (resData.screen !== undefined) {
        readCookie();
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const readCookie = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/read-cookie`,
        { credentials: 'include' },
      );
      const resData = await res.json();
      if (resData.screen !== undefined) {
        setScreen(resData.screen);
        navigate('/');
        return;
      }
    } catch (e) {
      setScreen('auth');
      console.log(e);
    }
  };

  const logoutAuth = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/auth/clear-cookie`, {
        method: 'GET',
      });
      setScreen('auth');
      navigate('/login');
      return;
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <AuthContext.Provider value={{ screen, loginAuth, logoutAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
export const useAuth = () => {
  return useContext(AuthContext);
};
