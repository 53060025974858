import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Grid,
  Paper,
  Title,
  Input,
  Select,
  ActionIcon
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';
import { STATES_ARRAY } from '../../types/members.types';
import { Pencil, Lock } from 'tabler-icons-react';

interface PersonalInformationTabProps {
  onFormSubmit?: (data: any) => void;
}

const PersonalInformationTab: React.FC<PersonalInformationTabProps> = ({ onFormSubmit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { control, register, handleSubmit, reset } = useForm();

  const onSubmit = async (data: any) => {
    try {
      if (onFormSubmit) {
        onFormSubmit(data);
      }
      notifications.show({
        title: 'Success',
        message: 'Form submitted successfully',
        color: 'green',
        position: 'bottom-right',
      });
      setIsEditing(false);
    } catch (error) {
      console.error('Error submitting form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit form',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  return (
    <Container>
      <Paper withBorder shadow="xs" p="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid justify="space-between" align="center">
            <Grid.Col span={11}>
              <Title order={3}>Personal Information</Title>
            </Grid.Col>
            <Grid.Col span={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ActionIcon
                onClick={() => setIsEditing((prev) => !prev)}
                variant="outline"
                color="blue"
              >
                {isEditing ? <Lock size={16} /> : <Pencil size={16} />}
              </ActionIcon>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="First Name"
                {...register('firstname', { required: true })}
                disabled={!isEditing}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Last Name"
                {...register('lastname', { required: true })}
                disabled={!isEditing}
              />
            </Grid.Col>
          </Grid>
          <TextInput
            label="Email"
            {...register('email', { required: true })}
            disabled={!isEditing}
          />
          <Controller
            name="birthdate"
            control={control}
            render={({ field }) => (
              <DateInput
                label="Date of Birth"
                value={field.value ? new Date(field.value) : undefined}
                onChange={(date) => field.onChange(date?.toISOString().slice(0, 10))}
                disabled={!isEditing}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <Input.Wrapper label="Phone Number">
                <Input
                  component={IMaskInput}
                  {...field}
                  mask="(000) 000-0000"
                  placeholder="(XXX) XXX-XXXX"
                  disabled={!isEditing}
                />
              </Input.Wrapper>
            )}
          />
          <Paper withBorder shadow="xs" p="md" mt="md">
            <Title order={3}>Address Information</Title>
            <TextInput label="Street 1" {...register('street1')} disabled={!isEditing} />
            <TextInput label="Street 2" {...register('street2')} disabled={!isEditing} />
            <Grid>
              <Grid.Col span={6}>
                <TextInput label="City" {...register('city')} disabled={!isEditing} />
              </Grid.Col>
              <Grid.Col span={6}>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label="State"
                      data={STATES_ARRAY}
                      clearable
                      searchable
                      disabled={!isEditing}
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput label="ZIP" {...register('zip')} disabled={!isEditing} />
              </Grid.Col>
              <Grid.Col span={6}>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label="Country"
                      data={[{ value: 'US', label: 'United States' }]}
                      clearable
                      disabled={!isEditing}
                    />
                  )}
                />
              </Grid.Col>
            </Grid>
            <TextInput label="County" {...register('county')} disabled={!isEditing} />
          </Paper>
          {isEditing && (
            <Button type="submit" mt="md">
              Submit
            </Button>
          )}
        </form>
      </Paper>
    </Container>
  );
};

export default PersonalInformationTab;
