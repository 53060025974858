import React from 'react';
import { ActionIcon } from '@mantine/core';
import { Home } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';

function HomeIcon() {
  const navigate = useNavigate();

  const goToAdminPortal = () => {
    navigate('/');
  };

  return (
    <ActionIcon
      variant="outline"
      color="blue"
      onClick={goToAdminPortal}
      title="Go to Admin Portal"
      style={{ position: 'absolute', top: 20, left: 20 }}
    >
      <Home style={{ width: 20, height: 20 }} />
    </ActionIcon>
  );
}

export default HomeIcon;
