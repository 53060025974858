import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Group,
  Collapse,
  Paper,
  Grid,
  MultiSelect,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import {
  SpecialtyFields,
  FormValues,
  specialties,
} from '../types/specialties.types';
import { notifications } from '@mantine/notifications';
import { useMemberContext } from './MemberContext';

const SpecialtyForm: React.FC = () => {
  const { memberId } = useMemberContext();
  const { control, register, handleSubmit, watch, setValue, reset } =
    useForm<FormValues>({
      defaultValues: {
        primaryspecialty: {
          memberId: memberId,
          type: 'primary',
          specialty: null,
          subspecialty: [],
          boardcertified: 'No',
          certificationnumber: '',
          certificationexpiration: null,
          recertificationdate: null,
          certifyingboard: '',
          certificationcountry: '',
          certificationstate: '',
          certificationcounty: '',
          certificationstreet1: '',
          certificationstreet2: '',
          certificationcity: '',
          certificationprovince: '',
          certificationzip: '',
        },
      },
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'secondaryspecialties',
  });

  const [collapsedIndices, setCollapsedIndices] = useState(
    fields.map(() => true),
  );

  const [initialData, setInitialData] = useState<FormValues>();

  const fetchSpecialties = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/specialties/${memberId}`,
      );
      if (!response.ok) {
        throw new Error('Failed to fetch specialties');
      }
      const fetchedSpecialties: SpecialtyFields[] = await response.json();
      if (fetchedSpecialties.length === 0) {
        console.log('No specialties found for this member.');
        return;
      }
  
      // Assume first specialty is the primary one
      const primarySpecialty = fetchedSpecialties.find(
        (s) => s.type === 'primary',
      );
      const secondarySpecialties = fetchedSpecialties
        .filter((s) => s.type === 'secondary')
        .sort((a, b) => a.id - b.id); // Sort by id
  
      // Reset the form with new data structure
      reset({
        primaryspecialty: primarySpecialty || {
          // Define default values if no primary is found
          memberId: memberId,
          type: 'primary',
          specialty: null,
          subspecialty: [],
          boardcertified: '',
          certificationnumber: '',
          certificationexpiration: null,
          recertificationdate: null,
          initialcertificationdate: null,
          certifyingboard: '',
          certificationcountry: '',
          certificationstate: '',
          certificationcounty: '',
          certificationstreet1: '',
          certificationstreet2: '',
          certificationcity: '',
          certificationprovince: '',
          certificationzip: '',
        },
        secondaryspecialties: secondarySpecialties,
      });
      if (primarySpecialty) {
        setInitialData({
          primaryspecialty: primarySpecialty,
          secondaryspecialties: secondarySpecialties,
        });
      } else {
        console.log('Not setting fetched value no primary specialty found');
      }
  
      console.log('Specialties fetched and form reset.');
    } catch (error) {
      console.error('Error fetching specialties:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch specialties',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };
  
  useEffect(() => {
    fetchSpecialties();
  }, [memberId]);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const onSubmit = async (data: FormValues) => {
    console.log('submit form values', data);

    const createSpecialty = async (specialty: SpecialtyFields) => {
      const endpoint = '/api/specialties';
      const method = 'POST';

      const response = await fetch(endpoint, {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(specialty),
      });

      return response.json();
    };

    const updateSpecialty = async (specialty: SpecialtyFields) => {
      const endpoint = `/api/specialties/${specialty.id}`;
      const method = 'PUT';

      const response = await fetch(endpoint, {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(specialty),
      });
      return response.json();
    };

    const deleteSpecialty = async (id: number) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/specialties/${id}`,
        {
          method: 'DELETE',
        },
      );
      console.log('delete response', response);
    };

    // Determine deletions, creations, and updates
    const initialSecondaryIds = new Set(
      initialData?.secondaryspecialties.map((s) => s.id),
    );
    const currentSecondaryIds = new Set(
      data.secondaryspecialties.map((s) => s.id),
    );
    const deletedIds = [...initialSecondaryIds].filter(
      (id) => !currentSecondaryIds.has(id),
    );

    const primaryTask =
      !data.primaryspecialty.id || data.primaryspecialty.id === 0
        ? createSpecialty(data.primaryspecialty)
        : updateSpecialty(data.primaryspecialty);

    const secondaryCreationTasks = data.secondaryspecialties
      .filter((s) => !s.id || s.id === 0)
      .map((s) => createSpecialty(s));
    const secondaryUpdateTasks = data.secondaryspecialties
      .filter((s) => s.id && s.id > 0 && initialSecondaryIds.has(s.id))
      .map((s) => updateSpecialty(s));

    const secondaryDeleteTasks = deletedIds.map(deleteSpecialty);

    try {
      const results = await Promise.all([
        primaryTask,
        ...secondaryCreationTasks,
        ...secondaryUpdateTasks,
        ...secondaryDeleteTasks,
      ]);
      console.log('Results:', results);
      fetchSpecialties(); // Refetch to sync with the latest backend state

      notifications.show({
        title: 'Success',
        message: 'Specialties updated successfully',
        color: 'green',
        position: 'bottom-right',
      });
    } catch (error) {
      console.error('Error submitting specialty form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit specialty',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  const primaryspecialty = watch(
    'primaryspecialty.specialty',
  ) as keyof typeof specialties;
  const secondaryspecialties = fields.map(
    (_, index) =>
      watch(
        `secondaryspecialties.${index}.specialty`,
      ) as keyof typeof specialties,
  );

  useEffect(() => {
    setValue('primaryspecialty.subspecialty', []);
  }, [primaryspecialty, setValue]);

  useEffect(() => {
    secondaryspecialties.forEach((specialty, index) => {
      setValue(`secondaryspecialties.${index}.subspecialty`, []);
    });
  }, [secondaryspecialties, setValue]);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper withBorder shadow="xs" p="md">
          <Group>
            <h3>Primary Specialty</h3>
          </Group>
          <Controller
            name="primaryspecialty.specialty"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Specialty"
                data={Object.keys(specialties)}
                clearable
              />
            )}
          />
          <Controller
            name="primaryspecialty.subspecialty"
            control={control}
            render={({ field }) => (
              <MultiSelect
                {...field}
                label="Subspecialty"
                data={primaryspecialty ? specialties[primaryspecialty] : []}
                clearable
              />
            )}
          />
          <Controller
            name="primaryspecialty.boardcertified"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Board Certified?"
                data={[
                  { value: 'Y', label: 'Yes' },
                  { value: 'N', label: 'No' },
                ]}
                clearable
              />
            )}
          />
          {watch('primaryspecialty.boardcertified') === 'Y' && (
            <>
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="Certification Number"
                    {...register('primaryspecialty.certificationnumber')}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="primaryspecialty.initialcertificationdate"
                    control={control}
                    render={({ field }) => (
                      <DateInput
                        {...field}
                        label="Initial Certification Date"
                        placeholder="Initial Certification Date"
                        value={field.value ? new Date(field.value) : null}
                        onChange={(date) =>
                          field.onChange(date ? date.toISOString() : null)
                        }
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="primaryspecialty.recertificationdate"
                    control={control}
                    render={({ field }) => (
                      <DateInput
                        {...field}
                        label="Last Recertification Date (If Applicable)"
                        placeholder="Recertification Date"
                        value={field.value ? new Date(field.value) : null}
                        onChange={(date) =>
                          field.onChange(date ? date.toISOString() : null)
                        }
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="primaryspecialty.certificationexpiration"
                    control={control}
                    render={({ field }) => (
                      <DateInput
                        {...field}
                        label="Expiration Date (If Applicable)"
                        placeholder="Expiration Date"
                        value={field.value ? new Date(field.value) : null}
                        onChange={(date) =>
                          field.onChange(date ? date.toISOString() : null)
                        }
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Certifying Board"
                    {...register('primaryspecialty.certifyingboard')}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`primaryspecialty.certificationcountry`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Country"
                        data={[{ value: 'US', label: 'United States' }]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="primaryspecialty.certificationstate"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="State"
                        data={[
                          'AK',
                          'AL',
                          'AR',
                          'AS',
                          'AZ',
                          'CA',
                          'CO',
                          'CT',
                          'DC',
                          'DE',
                          'FL',
                          'GA',
                          'GU',
                          'HI',
                          'IA',
                          'ID',
                          'IL',
                          'IN',
                          'KS',
                          'KY',
                          'LA',
                          'MA',
                          'MD',
                          'ME',
                          'MI',
                          'MN',
                          'MO',
                          'MP',
                          'MS',
                          'MT',
                          'NC',
                          'ND',
                          'NE',
                          'NH',
                          'NJ',
                          'NM',
                          'NV',
                          'NY',
                          'OH',
                          'OK',
                          'OR',
                          'PA',
                          'PR',
                          'RI',
                          'SC',
                          'SD',
                          'TN',
                          'TX',
                          'UM',
                          'UT',
                          'VA',
                          'VI',
                          'VT',
                          'WA',
                          'WI',
                          'WV',
                          'WY',
                        ].map((state) => ({ value: state, label: state }))}
                        clearable
                        searchable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Zip Code"
                    {...register('primaryspecialty.certificationzip')}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="County"
                    {...register('primaryspecialty.certificationcounty')}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="City"
                    {...register('primaryspecialty.certificationcity')}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Street 1"
                    {...register('primaryspecialty.certificationstreet1')}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Street 2"
                    {...register('primaryspecialty.certificationstreet2')}
                  />
                </Grid.Col>
              </Grid>
              <TextInput
                label="Province"
                {...register('primaryspecialty.certificationprovince')}
              />
            </>
          )}
        </Paper>

        {fields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group>
              <h3>Secondary Specialty {index + 1}</h3>
              <Button onClick={() => toggleCollapse(index)} size="xs">
                {collapsedIndices[index] ? 'Expand' : 'Collapse'}
              </Button>
              <Button color="red" onClick={() => remove(index)} size="xs">
                Remove
              </Button>
            </Group>
            <Collapse in={!collapsedIndices[index]}>
              <Controller
                name={`secondaryspecialties.${index}.specialty`}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Specialty"
                    data={Object.keys(specialties)}
                    clearable
                  />
                )}
              />
              <Controller
                name={`secondaryspecialties.${index}.subspecialty`}
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    {...field}
                    label="Subspecialty"
                    data={
                      secondaryspecialties[index]
                        ? specialties[secondaryspecialties[index]]
                        : []
                    }
                    clearable
                  />
                )}
              />
              <Controller
                name={`secondaryspecialties.${index}.boardcertified`}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Board Certified?"
                    data={[
                      { value: 'Y', label: 'Yes' },
                      { value: 'N', label: 'No' },
                    ]}
                    clearable
                  />
                )}
              />
              {watch(`secondaryspecialties.${index}.boardcertified`) ===
                'Y' && (
                <>
                  <Grid>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Certification Number"
                        {...register(
                          `secondaryspecialties.${index}.certificationnumber`,
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Controller
                        name={`secondaryspecialties.${index}.initialcertificationdate`}
                        control={control}
                        render={({ field }) => (
                          <DateInput
                            {...field}
                            label="Initial Certification Date"
                            placeholder="Initial Certification Date"
                            value={field.value ? new Date(field.value) : null}
                            onChange={(date) =>
                              field.onChange(date ? date.toISOString() : null)
                            }
                            clearable
                          />
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Controller
                        name={`secondaryspecialties.${index}.recertificationdate`}
                        control={control}
                        render={({ field }) => (
                          <DateInput
                            {...field}
                            label="Last Recertification Date (If Applicable)"
                            placeholder="Recertification Date"
                            value={field.value ? new Date(field.value) : null}
                            onChange={(date) =>
                              field.onChange(date ? date.toISOString() : null)
                            }
                            clearable
                          />
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Controller
                        name={`secondaryspecialties.${index}.certificationexpiration`}
                        control={control}
                        render={({ field }) => (
                          <DateInput
                            {...field}
                            label="Expiration Date (If Applicable)"
                            placeholder="Expiration Date"
                            value={field.value ? new Date(field.value) : null}
                            onChange={(date) =>
                              field.onChange(date ? date.toISOString() : null)
                            }
                            clearable
                          />
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Certifying Board"
                        {...register(
                          `secondaryspecialties.${index}.certifyingboard`,
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Controller
                        name={`secondaryspecialties.${index}.certificationcountry`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Country"
                            data={[{ value: 'US', label: 'United States' }]}
                            clearable
                            searchable
                          />
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Controller
                        name={`secondaryspecialties.${index}.certificationstate`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="State"
                            data={[
                              'AK',
                              'AL',
                              'AR',
                              'AS',
                              'AZ',
                              'CA',
                              'CO',
                              'CT',
                              'DC',
                              'DE',
                              'FL',
                              'GA',
                              'GU',
                              'HI',
                              'IA',
                              'ID',
                              'IL',
                              'IN',
                              'KS',
                              'KY',
                              'LA',
                              'MA',
                              'MD',
                              'ME',
                              'MI',
                              'MN',
                              'MO',
                              'MP',
                              'MS',
                              'MT',
                              'NC',
                              'ND',
                              'NE',
                              'NH',
                              'NJ',
                              'NM',
                              'NV',
                              'NY',
                              'OH',
                              'OK',
                              'OR',
                              'PA',
                              'PR',
                              'RI',
                              'SC',
                              'SD',
                              'TN',
                              'TX',
                              'UM',
                              'UT',
                              'VA',
                              'VI',
                              'VT',
                              'WA',
                              'WI',
                              'WV',
                              'WY',
                            ].map((state) => ({ value: state, label: state }))}
                            clearable
                          />
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Zip Code"
                        {...register(
                          `secondaryspecialties.${index}.certificationzip`,
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="County"
                        {...register(
                          `secondaryspecialties.${index}.certificationcounty`,
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="City"
                        {...register(
                          `secondaryspecialties.${index}.certificationcity`,
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Street 1"
                        {...register(
                          `secondaryspecialties.${index}.certificationstreet1`,
                        )}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Street 2"
                        {...register(
                          `secondaryspecialties.${index}.certificationstreet2`,
                        )}
                      />
                    </Grid.Col>
                  </Grid>
                  <TextInput
                    label="Province"
                    {...register(
                      `secondaryspecialties.${index}.certificationprovince`,
                    )}
                  />
                </>
              )}
            </Collapse>
          </Paper>
        ))}
        <Group mt="md" mb="lg">
          <Button
            onClick={() =>
              append({
                id: 0,
                memberId: memberId,
                type: 'secondary',
                specialty: null,
                subspecialty: [],
                boardcertified: 'No',
                certificationnumber: '',
                certificationexpiration: null,
                recertificationdate: null,
                initialcertificationdate: null,
                certifyingboard: '',
                certificationcountry: '',
                certificationstate: '',
                certificationcounty: '',
                certificationstreet1: '',
                certificationstreet2: '',
                certificationcity: '',
                certificationprovince: '',
                certificationzip: '',
              })
            }
          >
            Add Secondary Specialty
          </Button>
        </Group>

        <Group mt="md">
          <Button type="submit">Save</Button>
        </Group>
      </form>
    </Container>
  );
};

export default SpecialtyForm;
