import React, { useContext } from 'react';
import { ActionIcon, useMantineColorScheme, useComputedColorScheme } from '@mantine/core';
import { Sun, MoonStars } from 'tabler-icons-react';

function DarkModeToggle() {
  const {setColorScheme} = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme('light');
  const toggleColorScheme = () => {
    setColorScheme(computedColorScheme === 'dark' ? 'light' : 'dark')
  }

  return (
    <ActionIcon
      variant="outline"
      color={computedColorScheme === 'dark' ? 'yellow' : 'blue'}
      onClick={toggleColorScheme}
      title="Toggle dark mode"
      style={{ position: 'absolute', top: 20, right: 20 }}
    >
      {computedColorScheme === 'dark' ? (
        <Sun style={{ width: 20, height: 20 }} />
      ) : (
        <MoonStars style={{ width: 20, height: 20 }} />
      )}
    </ActionIcon>
  );
}

export default DarkModeToggle;
