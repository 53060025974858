import React from 'react';
import { ActionIcon } from '@mantine/core';
import { Logout } from 'tabler-icons-react';
import { useAuth } from './AuthProvider';
function LogoutIcon() {
  const { logoutAuth } = useAuth();
  const logout = () => {
    logoutAuth();
  };

  return (
    <ActionIcon
      variant="outline"
      color="red"
      onClick={logout}
      title="Logout"
      style={{ position: 'absolute', top: 20, left: 50 }}
    >
      <Logout style={{ width: 20, height: 20 }} />
    </ActionIcon>
  );
}

export default LogoutIcon;
