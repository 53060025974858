import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Textarea,
  Button,
  Container,
  Select,
  Group,
  Paper,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useMemberContext } from './MemberContext';

const Disclosures: React.FC = () => {
  const { memberId } = useMemberContext();
  const [disclosuresExist, setDisclosuresExist] = useState(false);
  const { control, register, handleSubmit, watch, reset } = useForm({});
  const watchAllFields = watch();

  const fetchDisclosures = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/disclosures/${memberId}`,
      );
      if (response.status === 404) {
        reset({
          licenserelinquished: null,
          licenserelinquisheddescription: '',
          challengetolicensure: null,
          challengetolicensuredescription: '',
          clinicalprivileges: null,
          clinicalprivilegesdescription: '',
          surrenderedprivileges: null,
          surrenderedprivilegesdescription: '',
          terminatedforcause: null,
          terminatedforcausedescription: '',
          placedonprobation: null,
          placedonprobationdescription: '',
          withdrawnunderinvestigation: null,
          withdrawnunderinvestigationdescription: '',
          revokedboardcertifications: null,
          revokedboardcertificationsdescription: '',
          notrecertifyboardcertifications: null,
          notrecertifyboardcertificationsdescription: '',
          deacdschallenges: null,
          deacdschallengesdescription: '',
          disciplinedbygovernmentprograms: null,
          disciplinedbygovernmentprogramsdescription: '',
          refusedprofessionalinsurance: null,
          refusedprofessionalinsurancedescription: '',
          deniedprofessionalinsurance: null,
          deniedprofessionalinsurancedescription: '',
        });
        setDisclosuresExist(false);
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to fetch disclosures');
      }

      const fetchedDisclosures = await response.json();
      reset(fetchedDisclosures);
      setDisclosuresExist(true);
    } catch (error) {
      console.error('Error fetching disclosures:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch disclosures',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  useEffect(() => {
    fetchDisclosures();
  }, [memberId]);

  const onSubmit = async (data: any) => {
    try {
      const method = disclosuresExist ? 'PUT' : 'POST';
      const url = disclosuresExist
        ? `/api/disclosures/${memberId}`
        : '/api/disclosures';

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...data, memberid: memberId }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit disclosures');
      }

      const result = await response.json();
      notifications.show({
        title: 'Success',
        message: 'Disclosures updated successfully',
        color: 'green',
        position: 'bottom-right',
      });

      if (!disclosuresExist) {
        fetchDisclosures();
      }
    } catch (error) {
      console.error('Error submitting form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit disclosures',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper shadow="xs" p="md" withBorder>
          <h3>Licensure</h3>
          <Controller
            name="licenserelinquished"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Has your license, registration or certification to practice in your profession ever been voluntarily or involuntarily relinquished, denied, suspended, revoked, restricted, or have you ever been subject to a fine, reprimand, consent order, probation or any conditions or limitations by any state or professional licensing, registration or certification board?"
                data={[
                  { value: 'Y', label: 'Yes' },
                  { value: 'N', label: 'No' },
                ]}
                clearable
              />
            )}
          />
          <Textarea
            label="Explanation"
            {...register('licenserelinquisheddescription')}
            style={{
              display:
                watchAllFields.licenserelinquished === 'Y' ? 'block' : 'none',
            }}
          />
          <Controller
            name="challengetolicensure"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Has there been any challenge to your licensure, registration or certification?"
                data={[
                  { value: 'Y', label: 'Yes' },
                  { value: 'N', label: 'No' },
                ]}
                clearable
              />
            )}
          />
          <Textarea
            label="Explanation"
            {...register('challengetolicensuredescription')}
            style={{
              display:
                watchAllFields.challengetolicensure === 'Y' ? 'block' : 'none',
            }}
          />
        </Paper>

        <div style={{ marginTop: '16px' }}></div>

        <Paper shadow="xs" p="md" withBorder>
          <h3>Hospital Privileges and Other Affiliations</h3>
          <Controller
            name="clinicalprivileges"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Have your clinical privileges or medical staff membership at any hospital or healthcare institution, voluntarily or involuntarily, ever been denied, suspended, revoked, restricted, denied renewal or subject to probationary or to other disciplinary conditions (for reasons other than non-completion of medical record when quality of care was not adversely affected) or have proceedings toward any of those ends been instituted or recommended by any hospital or healthcare institution, medical staff or committee, or governing board?"
                data={[
                  { value: 'Y', label: 'Yes' },
                  { value: 'N', label: 'No' },
                ]}
                clearable
              />
            )}
          />
          <Textarea
            label="Explanation"
            {...register('clinicalprivilegesdescription')}
            style={{
              display:
                watchAllFields.clinicalprivileges === 'Y' ? 'block' : 'none',
            }}
          />
          <Controller
            name="surrenderedprivileges"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Have you voluntarily or involuntarily surrendered, limited your privileges or not reapplied for privileges while under investigation?"
                data={[
                  { value: 'Y', label: 'Yes' },
                  { value: 'N', label: 'No' },
                ]}
                clearable
              />
            )}
          />
          <Textarea
            label="Explanation"
            {...register('surrenderedprivilegesdescription')}
            style={{
              display:
                watchAllFields.surrenderedprivileges === 'Y' ? 'block' : 'none',
            }}
          />
          <Controller
            name="terminatedforcause"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Have you ever been terminated for cause or not renewed for cause from participation, or been subject to any disciplinary action, by any managed care organizations (including HMOs, PPOs, or provider organizations such as IPAs, PHOs)?"
                data={[
                  { value: 'Y', label: 'Yes' },
                  { value: 'N', label: 'No' },
                ]}
                clearable
              />
            )}
          />
          <Textarea
            label="Explanation"
            {...register('terminatedforcausedescription')}
            style={{
              display:
                watchAllFields.terminatedforcause === 'Y' ? 'block' : 'none',
            }}
          />
        </Paper>

        <div style={{ marginTop: '16px' }}></div>

        <Paper shadow="xs" p="md" withBorder>
          <h3>Education, Training and Board Certification</h3>
          <Controller
            name="placedonprobation"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Were you ever placed on probation, disciplined, formally reprimanded, suspended or asked to resign during an internship, residency, fellowship, preceptorship or other clinical education program? If you are currently in a training program, have you been placed on probation, disciplined, formally reprimanded, suspended or asked to resign?"
                data={[
                  { value: 'Y', label: 'Yes' },
                  { value: 'N', label: 'No' },
                ]}
                clearable
              />
            )}
          />
          <Textarea
            label="Explanation"
            {...register('placedonprobationdescription')}
            style={{
              display:
                watchAllFields.placedonprobation === 'Y' ? 'block' : 'none',
            }}
          />
          <Controller
            name="withdrawnunderinvestigation"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Have you ever, while under investigation or to avoid an investigation, voluntarily withdrawn or prematurely terminated your status as a student or employee in any internship, residency, fellowship, preceptorship, or other clinical education program?"
                data={[
                  { value: 'Y', label: 'Yes' },
                  { value: 'N', label: 'No' },
                ]}
                clearable
              />
            )}
          />
          <Textarea
            label="Explanation"
            {...register('withdrawnunderinvestigationdescription')}
            style={{
              display:
                watchAllFields.withdrawnunderinvestigation === 'Y'
                  ? 'block'
                  : 'none',
            }}
          />
          <Controller
            name="revokedboardcertifications"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Have any of your board certifications or eligibility ever been revoked?"
                data={[
                  { value: 'Y', label: 'Yes' },
                  { value: 'N', label: 'No' },
                ]}
                clearable
              />
            )}
          />
          <Textarea
            label="Explanation"
            {...register('revokedboardcertificationsdescription')}
            style={{
              display:
                watchAllFields.revokedboardcertifications === 'Y'
                  ? 'block'
                  : 'none',
            }}
          />
          <Controller
            name="notrecertifyboardcertifications"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Have you ever chosen not to re-certify or voluntarily surrendered your board certification(s) while under investigation?"
                data={[
                  { value: 'Y', label: 'Yes' },
                  { value: 'N', label: 'No' },
                ]}
                clearable
              />
            )}
          />
          <Textarea
            label="Explanation"
            {...register('notrecertifyboardcertificationsdescription')}
            style={{
              display:
                watchAllFields.notrecertifyboardcertifications === 'Y'
                  ? 'block'
                  : 'none',
            }}
          />
        </Paper>

        <div style={{ marginTop: '16px' }}></div>

        <Paper shadow="xs" p="md" withBorder>
          <h3>DEA or CDS</h3>
          <Controller
            name="deacdschallenges"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Have your Federal DEA and/or State Controlled Dangerous Substances (CDS) certificate(s) or authorization(s) ever been challenged, denied, suspended, revoked, restricted, denied renewal, or voluntarily or involuntarily relinquished?"
                data={[
                  { value: 'Y', label: 'Yes' },
                  { value: 'N', label: 'No' },
                ]}
                clearable
              />
            )}
          />
          <Textarea
            label="Explanation"
            {...register('deacdschallengesdescription')}
            style={{
              display:
                watchAllFields.deacdschallenges === 'Y' ? 'block' : 'none',
            }}
          />
        </Paper>

        <div style={{ marginTop: '16px' }}></div>

        <Paper shadow="xs" p="md" withBorder>
          <h3>
            Medicare, Medicaid or other Governmental Program Participation
          </h3>
          <Controller
            name="disciplinedbygovernmentprograms"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Have you ever been disciplined, excluded from, debarred, suspended, reprimanded, sanctioned, censured, disqualified or otherwise restricted in regard to participation in the Medicare, Medicaid or other governmental programs, or in regard to receiving payment from any insurance company?"
                data={[
                  { value: 'Y', label: 'Yes' },
                  { value: 'N', label: 'No' },
                ]}
                clearable
              />
            )}
          />
          <Textarea
            label="Explanation"
            {...register('disciplinedbygovernmentprogramsdescription')}
            style={{
              display:
                watchAllFields.disciplinedbygovernmentprograms === 'Y'
                  ? 'block'
                  : 'none',
            }}
          />
        </Paper>

        <div style={{ marginTop: '16px' }}></div>

        <Paper shadow="xs" p="md" withBorder>
          <h3>
            Professional Liability Insurance Information and Claims History
          </h3>
          <Controller
            name="refusedprofessionalinsurance"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Has your professional liability insurance coverage ever been denied, canceled or non-renewed or initially refused upon application?"
                data={[
                  { value: 'Y', label: 'Yes' },
                  { value: 'N', label: 'No' },
                ]}
                clearable
              />
            )}
          />
          <Textarea
            label="Explanation"
            {...register('refusedprofessionalinsurancedescription')}
            style={{
              display:
                watchAllFields.refusedprofessionalinsurance === 'Y'
                  ? 'block'
                  : 'none',
            }}
          />
          <Controller
            name="deniedprofessionalinsurance"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Has your present professional liability insurance carrier excluded any specific procedures from your insurance coverage?"
                data={[
                  { value: 'Y', label: 'Yes' },
                  { value: 'N', label: 'No' },
                ]}
                clearable
              />
            )}
          />
          <Textarea
            label="Explanation"
            {...register('deniedprofessionalinsurancedescription')}
            style={{
              display:
                watchAllFields.deniedprofessionalinsurance === 'Y'
                  ? 'block'
                  : 'none',
            }}
          />
        </Paper>

        <Group mt="md">
          <Button type="submit">Save</Button>
        </Group>
      </form>
    </Container>
  );
};

export default Disclosures;
