import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Group,
  Collapse,
  Paper,
  Grid,
  Input,
  MultiSelect,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';

import { useMemberContext } from './MemberContext';

const LiabilityForm: React.FC = () => {
  const { memberId } = useMemberContext();
  const { control, register, handleSubmit, reset, watch } = useForm({});
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'liabilities',
  });

  const [collapsedIndices, setCollapsedIndices] = useState<boolean[]>([]);
  const [initialData, setInitialData] = useState<any>();
  const [practiceOptions, setPracticeOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const fetchLiabilities = async () => {
    console.log('Fetching liabilities for memberId:', memberId); // Debug statement
    try {
      const liabilitiesResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/liabilities/${memberId}`,
      );
      console.log('liabilities response status:', liabilitiesResponse.status); // Debug statement
      if (!liabilitiesResponse.ok) {
        throw new Error('Failed to fetch liabilities');
      }
      const fetchedLiabilities = await liabilitiesResponse.json();
      console.log('Fetched liabilities:', fetchedLiabilities); // Debug statement
  
      const sortedLiabilities = fetchedLiabilities.sort((a: any, b: any) => {
        const expirationDateComparison = new Date(a.currentexpirationdate).getTime() - new Date(b.currentexpirationdate).getTime();
        if (expirationDateComparison !== 0) return expirationDateComparison;
        const policyNumberComparison = a.policynumber.localeCompare(b.policynumber);
        if (policyNumberComparison !== 0) return policyNumberComparison;
        return a.id - b.id;
      });
  
      const liabilitiesextraResponse = await fetch(
        `/api/liabilitiesextra/${memberId}`,
      );
      console.log(
        'liabilities extra response status:',
        liabilitiesextraResponse.status,
      ); // Debug statement
      let fetchedExtra = { culturalcompetencytraining: '' };
  
      const practiceResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/practices/${memberId}`,
      );
      const practices = await practiceResponse.json();
      console.log('practiceResponse.json()', practiceResponse);
      setPracticeOptions(
        practices.map((p: any) => {
          return { value: p.id.toString(), label: p.legalbusinessname };
        }),
      );
      if (liabilitiesextraResponse.ok) {
        const textResponse = await liabilitiesextraResponse.text();
        if (textResponse) {
          fetchedExtra = JSON.parse(textResponse);
        }
      }
      console.log('Fetched liabilities extra:', fetchedExtra); // Debug statement
  
      reset({
        liabilities: sortedLiabilities,
        liabilitiesextra: fetchedExtra,
      });
      setInitialData({
        liabilities: sortedLiabilities,
        liabilitiesextra: fetchedExtra,
      });
      setCollapsedIndices(sortedLiabilities.map(() => true));
    } catch (error) {
      console.error('Error fetching liabilities:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch liabilities',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };
  
  useEffect(() => {
    if (memberId) {
      fetchLiabilities();
    }
  }, [memberId]);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const onSubmit = async (data: any) => {
    console.log('Submit form values:', data); // Debug statement
  
    const formatData = (items: any[]) => {
      return items.map((item) => ({
        ...item,
        memberid: memberId,
        coveredlocations: item.coveredlocations.map((location: string) => parseInt(location, 10)),
      }));
    };
  
    const formattedData = formatData(data.liabilities);
    console.log('formatted Data', formattedData);
  
    const createLiabilities = async (liabilities: any) => {
      console.log('Creating liabilities:', liabilities); // Debug statement
      const response = await fetch('/api/liabilities', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(liabilities),
      });
  
      console.log('Create liabilities response:', response); // Debug statement
      return response.json();
    };
  
    const updateLiabilities = async (liabilities: any) => {
      console.log('Updating liabilities:', liabilities); // Debug statement
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/liabilities/${liabilities.id}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(liabilities),
        },
      );
  
      console.log('Update liabilities response:', response); // Debug statement
      return response.json();
    };
  
    const deleteLiabilities = async (id: number) => {
      console.log('Deleting liabilities ID:', id); // Debug statement
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/liabilities/${id}`,
        {
          method: 'DELETE',
        },
      );
  
      console.log('Delete liabilities response:', response); // Debug statement
    };
  
    const createLiabilitiesExtra = async (extraData: any) => {
      const extraDataWithMemberId = { ...extraData, memberid: memberId }; // Ensure memberid is included
      console.log('Creating liabilities extra:', extraDataWithMemberId); // Debug statement
      const response = await fetch('/api/liabilitiesextra', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(extraDataWithMemberId),
      });
  
      console.log('Create liabilities extra response:', response); // Debug statement
      return response.json();
    };
  
    const updateLiabilitiesExtra = async (extraData: any) => {
      const extraDataWithMemberId = { ...extraData, memberid: memberId }; // Ensure memberid is included
      console.log('Updating liabilities extra:', extraDataWithMemberId); // Debug statement
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/liabilitiesextra/${memberId}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(extraDataWithMemberId),
        },
      );
  
      console.log('Update certification extra response:', response); // Debug statement
      return response.json();
    };
  
    const deleteLiabilitiesExtra = async () => {
      console.log('Deleting liabilities extra for memberId:', memberId); // Debug statement
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/liabilitiesextra/${memberId}`,
        {
          method: 'DELETE',
        },
      );
  
      console.log('Delete liabilities extra response:', response); // Debug statement
    };
  
    // Determine deletions, creations, and updates
    const initialIds = new Set<number>(
      initialData?.liabilities.map((c: any) => c.id),
    );
    const currentIds = new Set<number>(formattedData.map((c: any) => c.id));
    const deletedIds = [...initialIds].filter(
      (id): id is number => !currentIds.has(id),
    );
  
    const createTasks = formattedData
      .filter((c: any) => !c.id || c.id === 0)
      .map((c: any) => createLiabilities(c));
    const updateTasks = formattedData
      .filter((c: any) => c.id && c.id > 0 && initialIds.has(c.id))
      .map((c: any) => updateLiabilities(c));
  
    const deleteTasks = deletedIds.map((id) => deleteLiabilities(id));
  
    let extraTask = null;
    if (data.liabilitiesextra.culturalcompetencytraining) {
      extraTask =
        initialData?.liabilitiesextra &&
        initialData.liabilitiesextra.culturalcompetencytraining
          ? updateLiabilitiesExtra(data.liabilitiesextra)
          : createLiabilitiesExtra(data.liabilitiesextra);
    } else if (
      initialData?.liabilitiesextra &&
      initialData.liabilitiesextra.culturalcompetencytraining
    ) {
      extraTask = deleteLiabilitiesExtra();
    }
  
    try {
      const results = await Promise.all(
        [...createTasks, ...updateTasks, ...deleteTasks, extraTask].filter(
          Boolean,
        ),
      ); // Filter out null tasks
      console.log('Results:', results); // Debug statement
      fetchLiabilities();
  
      notifications.show({
        title: 'Success',
        message: 'Liabilities updated successfully',
        color: 'green',
        position: 'bottom-right',
      });
    } catch (error) {
      console.error('Error submitting liability form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit liability',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Col span={6}>
            <Controller
              name="coveredByFTCA"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="I am covered by FTCA"
                  data={[
                    { value: 'Y', label: 'Yes' },
                    { value: 'N', label: 'No' },
                  ]}
                  clearable
                />
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="uninsured"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="I am not insured"
                  data={[
                    { value: 'Y', label: 'Yes' },
                    { value: 'N', label: 'No' },
                  ]}
                  clearable
                />
              )}
            />
          </Grid.Col>
        </Grid>
        <div style={{ marginTop: '16px' }}></div>
        {fields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group>
              <h3>Policy {index + 1}</h3>
              <Button onClick={() => toggleCollapse(index)} size="xs">
                {collapsedIndices[index] ? 'Collapse' : 'Expand'}
              </Button>
              <Button color="red" onClick={() => remove(index)} size="xs">
                Remove
              </Button>
            </Group>
            <Collapse in={collapsedIndices[index]}>
              <Grid>
                <Grid.Col span={12}>
                  <TextInput
                    label="Policy Number"
                    {...register(`liabilities.${index}.policynumber`)}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Controller
                    name={`liabilities.${index}.coveredlocations`}
                    control={control}
                    render={({ field }) => (
                      <MultiSelect
                        {...field}
                        label="Covered Locations"
                        data={practiceOptions}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.currenteffectivedate`}
                    control={control}
                    render={({ field }) => (
                      <DateInput
                        {...field}
                        value={field.value ? new Date(field.value) : null}
                        onChange={(date) => field.onChange(date)}
                        label="Current Effective Date"
                        placeholder="Pick a date"
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.currentexpirationdate`}
                    control={control}
                    render={({ field }) => (
                      <DateInput
                        {...field}
                        value={field.value ? new Date(field.value) : null}
                        onChange={(date) => field.onChange(date)}
                        label="Current Expiration Date"
                        placeholder="Pick a date"
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.originaleffectivedate`}
                    control={control}
                    render={({ field }) => (
                      <DateInput
                        {...field}
                        value={field.value ? new Date(field.value) : null}
                        onChange={(date) => field.onChange(date)}
                        label="Original Effective Date"
                        placeholder="Pick a date"
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Carrier"
                    {...register(`liabilities.${index}.carrier`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Street 1"
                    {...register(`liabilities.${index}.street1`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Street 2"
                    {...register(`liabilities.${index}.street2`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="City"
                    {...register(`liabilities.${index}.city`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Province"
                    {...register(`liabilities.${index}.province`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.country`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Country"
                        data={[{ value: 'US', label: 'United States' }]}
                        clearable
                        searchable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.state`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="State"
                        data={[
                          'AK',
                          'AL',
                          'AR',
                          'AS',
                          'AZ',
                          'CA',
                          'CO',
                          'CT',
                          'DC',
                          'DE',
                          'FL',
                          'GA',
                          'GU',
                          'HI',
                          'IA',
                          'ID',
                          'IL',
                          'IN',
                          'KS',
                          'KY',
                          'LA',
                          'MA',
                          'MD',
                          'ME',
                          'MI',
                          'MN',
                          'MO',
                          'MP',
                          'MS',
                          'MT',
                          'NC',
                          'ND',
                          'NE',
                          'NH',
                          'NJ',
                          'NM',
                          'NV',
                          'NY',
                          'OH',
                          'OK',
                          'OR',
                          'PA',
                          'PR',
                          'RI',
                          'SC',
                          'SD',
                          'TN',
                          'TX',
                          'UM',
                          'UT',
                          'VA',
                          'VI',
                          'VT',
                          'WA',
                          'WI',
                          'WV',
                          'WY',
                        ].map((state) => ({ value: state, label: state }))}
                        clearable
                        searchable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="ZIP"
                    {...register(`liabilities.${index}.zip`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Input.Wrapper label="Phone">
                    <Input
                      component={IMaskInput}
                      mask="(000) 000-0000"
                      placeholder="(XXX) XXX-XXXX"
                      {...register(`liabilities.${index}.phone`)}
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Input.Wrapper label="Fax">
                    <Input
                      component={IMaskInput}
                      mask="(000) 000-0000"
                      placeholder="(XXX) XXX-XXXX"
                      {...register(`liabilities.${index}.fax`)}
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.unlimitedcoverage`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Unlimited Coverage"
                        data={[
                          { value: 'Y', label: 'Yes' },
                          { value: 'N', label: 'No' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Type"
                    {...register(`liabilities.${index}.type`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Amount Per Occurrence"
                    {...register(`liabilities.${index}.amountperoccurrence`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Amount Aggregate"
                    {...register(`liabilities.${index}.amountaggregate`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.tailnosecoverage`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Tail/Nose Coverage"
                        data={[
                          { value: 'Y', label: 'Yes' },
                          { value: 'N', label: 'No' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.individualcoverage`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Individual Coverage"
                        data={[
                          { value: 'Y', label: 'Yes' },
                          { value: 'N', label: 'No' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.selfinsured`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Self Insured"
                        data={[
                          { value: 'Y', label: 'Yes' },
                          { value: 'N', label: 'No' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Institution Affiliation"
                    {...register(`liabilities.${index}.institutionaffiliation`)}
                  />
                </Grid.Col>
              </Grid>
            </Collapse>
          </Paper>
        ))}
        <Group mt="md">
          <Button
            onClick={() =>
              append({
                policynumber: '',
                coveredlocations: [],
                currenteffectivedate: null,
                currentexpirationdate: null,
                originaleffectivedate: null,
                carrier: '',
                street1: '',
                street2: '',
                city: '',
                province: '',
                country: 'US',
                state: '',
                zip: '',
                phone: '',
                fax: '',
                unlimitedcoverage: '',
                type: '',
                amountperoccurrence: null,
                amountaggregate: null,
                tailnosecoverage: '',
                individualcoverage: '',
                selfinsured: '',
                institutionaffiliation: '',
              })
            }
          >
            Add Policy
          </Button>
        </Group>
        <Group mt="md">
          <Button type="submit">Save</Button>
        </Group>
      </form>
    </Container>
  );
};

export default LiabilityForm;
