import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Paper,
  Title,
  Grid,
} from '@mantine/core';
import {
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { notifications } from '@mantine/notifications';

const stripePromise = loadStripe('pk_test_51PdGuuHon8D4YU7NG1z8ZO4l3r67GXdiz9qd0BvJ44mKe9K2ZVJeubLskFrD43HwsydlsCMUZHzv6WO9NBFr9Q7500aNK8nczN');

const CheckoutForm: React.FC<{ clientSecret: string }> = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { register, handleSubmit } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data: any) => {
    if (!stripe || !elements) {
      return;
    }

    setIsSubmitting(true);

    const result = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: 'http://localhost:3000/memberportal',
      },
      redirect: 'if_required',
    });

    if (result.error) {
      notifications.show({
        title: 'Error',
        message: result.error.message || 'Failed to set up payment method',
        color: 'red',
        position: 'bottom-right',
      });
    } else {
      notifications.show({
        title: 'Success',
        message: 'Payment method set up successfully',
        color: 'green',
        position: 'bottom-right',
      });
    }

    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PaymentElement />
      <Button type="submit" disabled={isSubmitting} mt="md">
        Submit
      </Button>
    </form>
  );
};

const PaymentInformationTab: React.FC = () => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  useEffect(() => {
    fetch('/create-setup-intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  return (
    <Container>
      <Paper withBorder shadow="xs" p="md">
        <Grid justify="space-between" align="center">
          <Grid.Col span={11}>
            <Title order={3}>Payment Information</Title>
          </Grid.Col>
        </Grid>
        {clientSecret ? (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm clientSecret={clientSecret} />
          </Elements>
        ) : (
          <div>Loading...</div>
        )}
      </Paper>
    </Container>
  );
};

export default PaymentInformationTab;
