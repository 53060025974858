export type RaceEthnicity =
  | 'Asian'
  | 'Black or African American'
  | 'Hispanic or Latino'
  | 'Native American or Alaska Native'
  | 'Native Hawaiian or Other Pacific Islander'
  | 'White'
  | 'Other';
export type Language =
  | 'English'
  | 'Arabic'
  | 'Chinese'
  | 'French'
  | 'German'
  | 'Hindi'
  | 'Japanese'
  | 'Korean'
  | 'Portuguese'
  | 'Russian'
  | 'Spanish'
  | 'Vietnamese';
export type CareType = 'Primary' | 'Specialty' | 'Both';
export type Sex = 'M' | 'F';
export type PreferredCommunication = 'Email' | 'Phone';
export type Country = 'US';

export const STATES_ARRAY = [
  'AK',
  'AL',
  'AR',
  'AS',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MP',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UM',
  'UT',
  'VA',
  'VI',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];
export type State = (typeof STATES_ARRAY)[number];

export const LANGUAGES = [
  "English",
  "Advanced Cardiac Life Support (ACLS)",
  "Advanced Life Support in OB (ALSO)",
  "Advanced Trauma Life Support (ATLS)",
  "Amharic",
  "Amoy",
  "Arabic",
  "Armenian",
  "Azerbaijani",
  "Bengali",
  "Belarusian",
  "Bikol",
  "Bosnian",
  "Braille",
  "Burmese",
  "Canadian French",
  "Cantonese",
  "Catalan",
  "Cham",
  "Chamorro",
  "Chiu Chow",
  "Chinese",
  "Chinese-Simplified",
  "Chinese-Traditional",
  "Chow Jo",
  "Croatian",
  "Czech",
  "Danish",
  "Dari",
  "Dinka",
  "Dutch",
  "Esperanto",
  "Estonian",
  "Farsi",
  "Fijian",
  "Flemish",
  "French",
  "French Creole",
  "Fukienese",
  "Georgian",
  "German",
  "Greek",
  "Gujarati",
  "Hainanese",
  "Hakka",
  "Haitian Creole",
  "Hebrew",
  "Hindi",
  "Hmong",
  "Hungarian",
  "Icelandic",
  "Ilocano",
  "Ilongo",
  "Indonesian",
  "Italian",
  "Japanese",
  "Kazakh",
  "Khmhu",
  "Khmer",
  "Kinyarwanda",
  "Kirundi",
  "Korean",
  "Kurdish",
  "Laotian",
  "Latin",
  "Latvian",
  "Lithuanian",
  "Macedonian",
  "Malay",
  "Malayalam",
  "Mandarin",
  "Mandinka",
  "Marathi",
  "Marshallese",
  "Mien",
  "Mongolian",
  "Nepali",
  "Navajo",
  "Norwegian",
  "Nuer",
  "Oromo",
  "Palauan",
  "Pampangan",
  "Pangasinan",
  "Pashtu",
  "Pediatric Advanced Life Support (PALS)",
  "Phonetic",
  "Polish",
  "Portuguese",
  "Punjabi",
  "Quechua",
  "Real Time & Remote Captioning",
  "Romanian",
  "Russian",
  "Samoan",
  "Serbian",
  "Shanghainese",
  "Sherpa",
  "Shona",
  "Slovak",
  "Slovenian",
  "Somali",
  "Spanish",
  "Swahili",
  "Swedish",
  "Tagalog",
  "Taiwanese",
  "Taishanese",
  "Tamil",
  "Tactile",
  "Thai",
  "Tibetan",
  "Tigrigna",
  "Tongan",
  "Trukese",
  "Turkmen",
  "Turkish",
  "UK English / British English",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Visayan",
  "Vietnamese",
  "Wolof",
  "Xhosa",
  "Yoruba",
  "Zulu"
];

export interface Member {
  id: number;
  npi?: string;
  firstname?: string;
  lastname?: string;
  middlename?: string;
  fullname?: string;
  preferredname?: string;
  sex?: Sex;
  birthday?: string;
  birthcity?: string;
  birthstate?: State;
  ssn?: string;
  mobilephone?: string;
  email?: string;
  preferredcommunication?: PreferredCommunication;
  caretype?: CareType;
  raceethnicity?: RaceEthnicity;
  languages?: Language[];
  specialexperiences?: string[];
  homestreet1?: string;
  homestreet2?: string;
  homecity?: string;
  homestate?: State;
  homezip?: string;
  homecountry?: Country;
  homecounty?: string;
  mailingstreet1?: string;
  mailingstreet2?: string;
  mailingcity?: string;
  mailingstate?: State;
  mailingzip?: string;
  mailingcountry?: Country;
  mailingcounty?: string;
}
