import React, { createContext, useContext, useState, ReactNode } from 'react';

interface MemberContextProps {
  isNewMember: boolean;
  setIsNewMember: (isNew: boolean) => void;
  memberId: number;
  setMemberId: (id: number) => void;
}

const MemberContext = createContext<MemberContextProps | undefined>(undefined);

interface MemberProviderProps {
  children: ReactNode;
}

export const MemberProvider: React.FC<MemberProviderProps> = ({ children }) => {
  const [isNewMember, setIsNewMember] = useState<boolean>(false);
  const [memberId, setMemberId] = useState<number>(0);

  return (
    <MemberContext.Provider
      value={{ isNewMember, setIsNewMember, memberId, setMemberId }}
    >
      {children}
    </MemberContext.Provider>
  );
};

export const useMemberContext = (): MemberContextProps => {
  const context = useContext(MemberContext);
  if (!context) {
    throw new Error('useMemberContext must be used within a MemberProvider');
  }
  return context;
};
